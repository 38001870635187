<template>
  <div class="common-page">
    <div class="common-search-box" style="padding-right:230px">
      <el-form :inline="true" class="demo-form-inline" :size="$store.state.settingInfo.size">
        <el-form-item label="小区" >
          <el-select v-model="searchParams.depId" placeholder="请选择小区" clearable @change="onDeptChange">
            <el-option v-for="(item, index) in depList" :key="index" :label="item.depName" :value="item.depId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼栋" >
          <el-select v-model="searchParams.buildingId" placeholder="请选择楼栋" >
            <el-option v-for="(item, index) in buildData" :key="index" :label="item.buildingName" :value="item.buildingId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间号">
          <el-input v-model="searchParams.roomNo" placeholder="请输入房间号"></el-input>
        </el-form-item>
        <el-form-item label="车位号" >
          <el-input v-model="searchParams.psNo" placeholder="请输入账单号"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="searchParams.userName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="发票号">
          <el-input v-model="searchParams.invoiceNo" placeholder="请输入发票号"></el-input>
        </el-form-item>
        <el-form-item label="费用周期" >
          <el-select v-model="searchParams.fcId" placeholder="请选择费用周期" >
            <el-option v-for="(item, index) in paymentCycleData" :key="index" :label="item.name" :value="item.fcId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账单类型" >
          <el-select :multiple="true" collapse-tags v-model="searchParams.billTypes" placeholder="请选择账单类型" clearable>
            <el-option label="房屋物业费" :value="0"></el-option>
            <el-option label="车位服务费" :value="1"></el-option>
            <el-option label="水电公摊" :value="2"></el-option>
            <el-option label="装修" :value="3"></el-option>
            <el-option label="押金" :value="4"></el-option>
            <el-option label="门禁卡" :value="5"></el-option>
            <el-option label="房屋租金" :value="6"></el-option>
            <el-option label="车位租金" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账单状态" >
          <el-select :multiple="true" collapse-tags v-model="searchParams.paymentStates" placeholder="请选择账单状态" clearable>
            <el-option label="已缴费" value="0"></el-option>
            <el-option label="未缴费" value="1"></el-option>
            <el-option label="部分支付" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" >
          <el-select v-model="searchParams.paymentTypes" placeholder="请选择支付方式" :multiple="true" collapse-tags clearable>
            <el-option label="自动扣款" :value="1"></el-option>
              <el-option label="刷卡" :value="2"></el-option>
              <el-option label="现金" :value="3"></el-option>
              <el-option label="微信" :value="4"></el-option>
              <el-option label="支付宝" :value="5"></el-option>
              <el-option label="建设支付" :value="6"></el-option>
              <el-option label="小程序" :value="7"></el-option>
              <el-option label="业委代收" :value="8"></el-option>
              <el-option label="银行代扣" :value="9"></el-option>
              <el-option label="转账" :value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" >
          <el-date-picker
            v-model="searchParams.startDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate: (time) => {
                if (this.searchParams.endDate) {
                  return time.getTime() > new Date(this.searchParams.endDate).getTime();
                }
              },
            }"  
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" >
          <el-date-picker
            v-model="searchParams.endDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate: (time) => {
                if (this.searchParams.startDate) {
                  return time.getTime() < new Date(this.searchParams.startDate).getTime() - 86400000 ;
                }
              },
            }"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付开始时间" >
          <el-date-picker
            v-model="searchParams.payStartDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate: (time) => {
                if (this.searchParams.payEndDate) {
                  return time.getTime() > new Date(this.searchParams.payEndDate).getTime();
                }
              },
            }"  
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付结束时间" >
          <el-date-picker
            v-model="searchParams.payEndDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="{
                disabledDate: (time) => {
                if (this.searchParams.payStartDate) {
                  return time.getTime() < new Date(this.searchParams.payStartDate).getTime() - 86400000 ;
                }
              },
            }"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" >
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="onReset" >重置</el-button>
        </el-form-item>
      </el-form>
      <div class="common-export-btn"> 
        <el-button type="primary" :loading="btnLoading" @click="onExport" :size="$store.state.settingInfo.size">导出</el-button>
        <!-- <el-button type="primary" @click="onBatchExport" :size="$store.state.settingInfo.size">账单打印</el-button>
        <el-button type="primary" @click="onEdit" :size="$store.state.settingInfo.size">交费</el-button>
        <el-button type="primary" :loading="btnLoading" @click="onExport" :size="$store.state.settingInfo.size">导出催账单</el-button> -->
      </div>
    </div><div class="table" ref="table">
      <el-table
        border
        :data="tableData"
        :max-height="heights"
        show-summary
        :summary-method="getSummaries"
        :size="$store.state.settingInfo.size"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          fixed="left"
          width="50">
        </el-table-column>
        <el-table-column
          prop="departmentDto.depName"
          width="120px"
          fixed="left"
          label="小区">
        </el-table-column>
        <el-table-column
          fixed="left"
          prop="buildingNo"
          width="80px"
          label="楼栋">
        </el-table-column>
        <el-table-column
          fixed="left"
          prop="roomNo"
          width="80px"
          label="房号">
        </el-table-column>
        <el-table-column
          prop="invoiceNo"
          width="100px"
          label="发票号">
        </el-table-column>
        <el-table-column
          prop="roomArea"
          label="房屋面积">
        </el-table-column>
        <el-table-column
          prop="roomType"
          label="房屋类型">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.roomDto.roomType == 0">住宅</span>
              <span v-if="scope.row.roomDto.roomType == 1">商铺</span>
              <span v-if="scope.row.roomDto.roomType == 2">别墅</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomState"
          label="出售状态">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.roomDto.roomState == 0">已售</span>
              <span v-if="scope.row.roomDto.roomState == 1">出租</span>
              <!-- <span v-if="scope.row.roomState == 2">已售转售</span> -->
              <span v-if="scope.row.roomDto.roomState == 3">空闲</span>
              <span v-if="scope.row.roomDto.roomState == 4">达收楼条件未收楼</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="psNo"
          width="80px"
          label="车位号">
        </el-table-column>
        <el-table-column
          prop="parkingSpaceDto.psState"
          label="车位状态">
          <template slot-scope="scope">
            <div v-if="scope.row.parkingSpaceDto">
              <span v-if="scope.row.parkingSpaceDto.psState == 0">空闲</span>
              <span v-if="scope.row.parkingSpaceDto.psState == 1">已售</span>
              <span v-if="scope.row.parkingSpaceDto.psState == 2">出租</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="parkingSpaceDto.parkingTypeDto.ptName"
          width="120"
          label="车位类型">
        </el-table-column>
        <el-table-column
          prop="remark"
          width="120"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="userDto.userName"
          width="100"
          label="缴费人">
        </el-table-column> 
        <el-table-column
          prop="billNo"
          width="100"
          label="费用类型">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.billType == 0">房屋物业费</span>
              <span v-if="scope.row.billType == 1">车位服务费</span>
              <span v-if="scope.row.billType == 2">水电公摊</span>
              <span v-if="scope.row.billType == 3">装修</span>
              <span v-if="scope.row.billType == 4">押金</span>
              <span v-if="scope.row.billType == 5">门禁卡</span>
              <span v-if="scope.row.billType == 6">房屋租金</span>
              <span v-if="scope.row.billType == 7">车位租金</span>
              <span v-if="scope.row.billType == 8">预交费</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="feeCycle"
          width="160"
          label="费用期间">
        </el-table-column>
        <el-table-column
          prop="billNo"
          width="160"
          label="账单编号">
        </el-table-column>
        <el-table-column
          prop="paymentState"
          label="账单状态">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.paymentState == 0">已缴费</span>
              <span v-if="scope.row.paymentState == 1">未缴费</span>
              <span v-if="scope.row.paymentState == 2">部分支付</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="paidAmount11"
          width="120"
          label="缴费方式">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.paymentType == 1">自动扣款</span>
              <span v-if="scope.row.paymentType == 2">刷卡</span>
              <span v-if="scope.row.paymentType == 3">现金</span>
              <span v-if="scope.row.paymentType == 4">微信</span>
              <span v-if="scope.row.paymentType == 5">支付宝</span>
              <span v-if="scope.row.paymentType == 6">建设支付</span>
              <span v-if="scope.row.paymentType == 7">小程序</span>
              <span v-if="scope.row.paymentType == 8">业委代收</span>
              <span v-if="scope.row.paymentType == 9">银行代扣</span>
              <span v-if="scope.row.paymentType == 10">转账</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="paidAmount"
          width="160"
          label="实际应付金额">
        </el-table-column>
        <el-table-column
          prop="payAmount"
          width="120"
          label="缴费金额">
        </el-table-column>
        <el-table-column
          prop="advancePaymentAmount"
          width="130"
          label="转为已交费">
        </el-table-column>
        <el-table-column
          prop="payableAmount"
          width="120"
          label="应收金额">
        </el-table-column>
        <el-table-column
          prop="discountAmount"
          label="优惠金额">
        </el-table-column>
        <el-table-column
          prop="paymentDate"
          width="140"
          label="收款时间">
        </el-table-column>
        <!-- <el-table-column
          prop="paymentDate"
          label="支付状态">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.paymentState == 0">已缴费</span>
              <span v-if="scope.row.paymentState == 1">未缴费</span>
              <span v-if="scope.row.paymentState == 2">部分支付</span>
            </div>
          </template>
        </el-table-column>  -->
        <el-table-column
          prop="name"
          width="120"
          fixed="right"
          label="操作">
          <template slot-scope="scope">
            <el-button
              class="common-operate-btn"
              type="text"
              @click="onPrint(scope.row)"
              size="small">
              收据打印
            </el-button>
            <el-button
              class="common-operate-btn"
              type="text"
              @click="onDelete(scope.row)"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <CommonPage :searchParams="searchParams" @onSizChange="onSizChange" @onCurrentChange="onCurrentChange" :total="total"></CommonPage>
  </div>
</template>
<script>
import minxins from '@/minxins/index'
export default {
  data () {
    return {
      searchParams: {
        needBuildFlag: true,
        limit: true,
        fcId: '',
        buildingId: '',
        paymentStates: [],
        pageNum: 1,
        depId: '',
        pageSize: 10
      },
      btnLoading: false,
      total: 0,
      tableData: [],
      depList: [],
    }
  },
  mixins: [minxins],
  mounted () {
    this.getAllDerp()
    this.getInfo()
  },
  methods: {
    onDelete (params) {
      this.$confirm('是否删除数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$fetch.post(`/pay/deletePayRecord`, { payId: params.payId }).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.onSearch()
          }
        })
      })
    },
    onDeptChange () {
      this.searchParams.buildingId = ''
      this.searchParams.fcId = ''
      this.getBuildings(this.searchParams.depId)
      this.getPaymentCycle(this.searchParams.depId)
    },
    
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (
          !values.every(value => isNaN(value)) 
          && (
            column.property == 'advancePaymentAmount' || 
            column.property == 'payableAmount' || 
            column.property == 'discountAmount' || 
            column.property == 'prepaidAmount' || 
            column.property == 'paidAmount' || 
            column.property == 'payAmount' || 
            column.property == 'shouldPayAmount'
            )) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2) + ' 元';
        } else {
          sums[index] = '';
        }
      });
      console.log(sums)
      return sums;
    },
    onPrint (params) {
      window.open(`/manage/export/exportByInvoice?invoiceId=${params.invoiceId}`)
    },
    onExport () {
      this.btnLoading = true
      this.$fetch.post('/pay/exportBillPays', {
        ...this.searchParams,
        limit: false,
      }, { 
        responseType: 'blob', 
      }).then(res => {
        this.btnLoading = false
        let name = this.tableData[0].departmentDto.depName + '_' + this.$moment(new Date()).format('YYYY-MM-DD') + '.xls'
        this.$utils.exportFile(res, name)
      })
    },
    getInfo () {
      this.$bus.$emit('createLoad', true)
      this.$fetch.post('/pay/queryBillPays', {
        ...this.searchParams
      }).then(res => {
        this.$bus.$emit('createLoad', false)
        this.tableData = []
        this.total = 0
        if (res.code == 200 && res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        }
      })
    },
    getAllDerp () {
      this.$fetch.post('/department/queryDeps', {
        limit: false
      }).then(res => {
        if (res.code == 200 && res.result) {
          this.depList = res.result.list
        }
      })
    },
    onSizChange (size) {
      this.searchParams.pageSize = size
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    onCurrentChange (num) {
      this.searchParams.pageNum = num
      this.getInfo()
    },
    onReset () {
      for(let i in this.searchParams) {
        if (i != 'limit' && i != 'pageNum' && i != 'pageSize') {
          if (this.searchParams[i] instanceof Array) {
            this.searchParams[i] = []
          } else {
            this.searchParams[i] = ''
          }
        }
      }
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    onSearch () {
      this.searchParams.pageNum = 1
      this.getInfo()
    },
  }
}
</script>